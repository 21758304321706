<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">
          <div class="d-flex align-start text-h6">{{ $t('earn_points_head') }}
            <v-tooltip top max-width="340" color="">
              <template v-slot:activator="{ on }">
                <v-icon color="" class="ml-2 pt-1" v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span v-html="$t('earn_points_info')"></span>
            </v-tooltip>
          </div>
          <div class="mt-8 mb-4">
            <v-row>
              <v-col cols>
                <x-text-field
                  type="text"
                  readonly
                  id="customerNumber"
                  :value="$auth.user().customer_number"
                  :hide-details="true"
                ></x-text-field>
              </v-col>
              <v-col cols="auto">
                <div class="d-flex mt-2">
                  <v-btn
                    depressed 
                    fab
                    small
                    class="btn-copy no-caps"
                    @click="copyStringToClipboard($auth.user().customer_number)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-card outlined class="d-flex flex-column" :style="{
            'max-width': qrWidth + 'px',
            'overflow': 'hidden',
            'margin': 'auto'
          } ">
          <qrcode v-if="!$vuetify.theme.dark" class="mt-0" :value="$auth.user().customer_number" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff00', dark: ($vuetify.theme.dark) ? '#ffffff' : '#000000' } }"></qrcode>
            <qrcode v-if="$vuetify.theme.dark" class="mt-0" :value="$auth.user().customer_number" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff', dark: '#000000' } }"></qrcode>
            <!-- <qrcode v-if="!$vuetify.theme.dark" class="mt-0" :value="$init.config.schemeAndHost + '/business/issue-points?c=' + $auth.user().customer_number" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff00', dark: ($vuetify.theme.dark) ? '#ffffff' : '#000000' } }"></qrcode>
            <qrcode v-if="$vuetify.theme.dark" class="mt-0" :value="$init.config.schemeAndHost + '/business/issue-points?c=' + $auth.user().customer_number" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff', dark: '#000000' } }"></qrcode> -->
          </v-card>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { copyStringToClipboard } from '@/utils/helpers';
// import MenuCustomer from '@/components/ui/MenuCustomer.vue'
export default {
  // components: {
  //   MenuCustomer
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: []
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))
  },
  methods: {
    copyStringToClipboard,
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
  },
  computed: {
    qrWidth: function () {
      let Width = parseInt(window.innerWidth)
      let w = Width
      const p = 64
      return (w > (416 + p)) ? 416 : w - p
    }
  }
}
</script>